import React from 'react';

const AboutUs = () => {
  return (
    <>
      <div className="container py-14 px-4" id="about">
        <h1
          data-aos="zoom-in"
          className="text-3xl sm:text-4xl font-bold max-w-[650px] mx-auto text-center font-extrabold"
        >
          Who We Are
        </h1>
        <div class="font-[sans-serif]px-6 py-12">
          <div class="grid lg:grid-cols-2 gap-8 max-lg:max-w-2xl mx-auto">
            <div class="text-left">
              <h2
                data-aos="fade-up"
                data-aos-delay="300"
                className="text-xl sm:text-xl font-extrabold"
              >
                Our Expertise
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="600"
                class="mb-4 p-3 text-xl sm:text-sm text-justify"
              >
                Our talented developers create engaging and effective websites
                and online apps using the React technology. SEO and SEM: By
                using search engine marketing (SEM) and search engine
                optimisation (SEO) techniques, we increase your website's online
                exposure and increase traffic. Social media marketing: Our team
                assists you in developing a powerful, interesting online
                presence across a range of social media channels, from content
                development to community management. Email marketing: We design
                audience-specific email campaigns that convert prospects into
                devoted clients. Get to Know Our Staff.
              </p>
              <h2
                data-aos="fade-up"
                data-aos-delay="300"
                className="text-xl sm:text-xl font-extrabold"
              >
                Leadership
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="600"
                class="mb-4 p-3 text-xl sm:text-sm text-justify"
              >
                [Name], the founder and CEO: [Name] have a background in
                [relevant subject] and approaches digital marketing with a depth
                of expertise and vision. [Name], CTO: With a focus on web
                development and React, [Name] inventively and precisely lead our
                tech team.
              </p>
              <h2
                data-aos="fade-up"
                data-aos-delay="300"
                className="text-xl sm:text-xl font-extrabold"
              >
                Development Team
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="600"
                class="mb-4 p-3 text-xl sm:text-sm text-justify"
              >
                A team of experienced and driven React developers who are
                committed to creating digital solutions that are easy to use and
                responsive.
              </p>
              <h2
                data-aos="fade-up"
                data-aos-delay="300"
                className="text-xl sm:text-xl font-extrabold"
              >
                Marketing Team
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="600"
                class="mb-4 p-3 text-xl sm:text-sm text-justify"
              >
                A creative and strategic bunch, skilled in various aspects of
                digital marketing from SEO to social media engagement.
              </p>
              <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-8 mt-12">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    class="fill-green-500 shrink-0"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <h6 class="text-base font-semibold ml-4">Customization</h6>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    class="fill-green-500 shrink-0"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <h6 class="text-base font-semibold ml-4">Security</h6>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    class="fill-green-500 shrink-0"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <h6 class="text-base font-semibold ml-4">Support</h6>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    class="fill-green-500 shrink-0"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <h6 class="text-base font-semibold ml-4">Performance</h6>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    class="fill-green-500 shrink-0"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <h6 class="text-base font-semibold ml-4">Global Reach</h6>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    class="fill-green-500 shrink-0"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <h6 class="text-base font-semibold ml-4">Communication</h6>
                </div>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <img
                src="https://readymadeui.com/management-img.webp"
                alt="Placeholder Image"
                class="rounded-lg object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
